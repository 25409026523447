import React, { useEffect } from "react"
import { navigate } from "gatsby"

const DdosRedirectionPage2 = () => {
  useEffect(() => {
    navigate("/solutions/network/ddos-mitigation")
  }, [])

  return <></>
}

export default DdosRedirectionPage2
